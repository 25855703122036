// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  companyName: 'Remax',
  node: 'ec',
  apiUrl: 'https://api-ec.redremax.com/remaxweb-ec/api/',
  host: 'https://www.remax.com.ec',
  googleAPIKey: 'AIzaSyA7-mFEJV0WMTxUDqkMzuQQvqcEDYQMVmk',
  googleAPISecret: '_PRILVk4IOr9RyyZvAVRUCxHQyw=',
  testUser: 'Xzjn6gXv3g',
  testPass: '9WJeBNLsut',
  imageBucket: 'prod-ec-remax-web-assets',
  imageCloudfront: 'https://d2hy2ig0r5r41b.cloudfront.net',
  sitemapUrl: 'https://prod-ec-remax-web-assets.s3.amazonaws.com',
  robotUrl: 'https://prod-ec-remax-web-assets.s3.amazonaws.com/robots.txt',
  imageBucketResizeUrl: 'https://d2hy2ig0r5r41b.cloudfront.net/',
  cognito: {
    aws_user_pools_id: null,
    aws_user_pools_web_client_id: null,
    domain: null,
    redirectSignIn: null,
    redirectSignOut: null,
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
